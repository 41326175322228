<template>
  <a-config-provider :locale="locale">
    <StyleProvider v-if="extract" :cache="cache">
      <slot/>
    </StyleProvider>
    <slot v-else/>
    <GlobalModals/>
  </a-config-provider>
</template>
<script lang="ts" setup>
import {useHead, useNuxtApp} from '#imports';
import {createCache, extractStyle, StyleProvider} from 'ant-design-vue';
import locale from 'ant-design-vue/locale/de_DE';

const cache = createCache();

const config = useRuntimeConfig();
const app = useNuxtApp();
const extract = config.nodeEnv !== 'development';

if (extract) {
// Real-time style extraction at ssr
// Pre-rendered extraction styles for ssg
  app.hook('app:rendered', () => {
    useHead({
      style: [extractStyle(cache, true)]
    });
  });
}
</script>
